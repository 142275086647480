import $ from 'jquery'

$(document).ready(function() {
    $(document).on('click', '#selectCategory', function() {
        console.log('13123')
        if($('.select-category-dialog').attr('class').includes('show')) {
            $('.select-category-dialog').removeClass('show')
        }
        else {
            $('.select-category-dialog').addClass('show')
        }
    })
    $(document).on('mousedown', '.search-modal-wrapper', function() {
        $('.search-modal-wrapper').removeClass('active')
    })
    $(document).on('mousedown', '.search-modal', function(e) {
        e.stopPropagation()
    })
})
$(document).keydown(function (event) {
    if (event.keyCode === 123) { // Prevent F12
        return false;
    } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) { // Prevent Ctrl+Shift+I        
        return false;
    }
});