import React from 'react'
import Cookies from 'universal-cookie'
import { CookieKey } from '../../constants/CookieKey'

export class EnChange extends React.Component<{}> {
    constructor(props: any) {
        super(props)
        const cookies = new Cookies()
        cookies.set(CookieKey.language, 'en')
        window.location.href = '/'
    }
}