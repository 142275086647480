import $ from 'jquery'

$(document).ready(function() {
    $('.faq-item h4').on('click', function() {
        if($(this).parent().attr('class').includes('show')) {
            $(this).parent().removeClass('show')
        }
        else {
            $(this).parent().addClass('show')
        }
    })
})