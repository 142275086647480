import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export class LeftNavigationItem extends React.Component<{icon: any, name: string, active: boolean}> {
    render(): React.ReactNode {
        return <>
            <div className={'left-navigation-item' + (this.props.active ? ' active' : '')}>
                <div className='icon'>  
                    <FontAwesomeIcon icon={this.props.icon} />
                </div>
                <div className='name'>
                    {this.props.name}
                </div>
            </div>
        </>
    }
}