import Cookies from 'universal-cookie';
import { CookieKey } from '../constants/CookieKey';

export default function translator(key: String) : string {
    var wordData : object = {
        'Login / Register': {
            'vi': 'Đăng nhập / Đăng ký',
            'en': 'Login / Register'
        },
        'Shop': {
            'vi': 'Cửa hàng',
            'en': 'Shop'
        },
        'Frame Sample': {
            'vi': 'Khung mẫu',
            'en': 'Frame Sample'
        },
        'About Us': {
            'vi': 'Về chúng tôi',
            'en': 'About Us'
        },
        'Contact Us': {
            'vi': 'Liên hệ',
            'en': 'Contact Us'
        },
        'HOME': {
            'vi': 'TRANG CHỦ',
            'en': 'HOME'
        },
        'SHOP': {
            'vi': 'CỬA HÀNG',
            'en': 'SHOP'
        },
        'Showing 1-22 of 44 results': {
            'vi': 'Hiển thị 1-22 trên tổng số 44',
            'en': 'Showing 1-22 of 44 results'
        },
        'Default Sorting': {
            'vi': 'Sắp xếp mặc định',
            'en': 'Default Sorting'
        },
        'Sort by latest': {
            'vi': 'Mới nhất',
            'en': 'Sort by latest'
        },
        'Sort by price: High to Low': {
            'vi': 'Giá giảm dần',
            'en': 'Sort by price: High to Low'
        },
        'Sort by price: Low to High': {
            'vi': 'Giá tăng dần',
            'en': 'Sort by price: Low to High'
        },
        'Category': {
            'vi': 'Danh mục',
            'en': 'Category'
        },
        'Filter by price': {
            'vi': 'Lọc theo giá',
            'en': 'Filter by price'
        },
        'From': {
            'vi': 'Từ',
            'en': 'From'
        },
        'To': {
            'vi': 'Đến',
            'en': 'To'
        },
        'Filter': {
            'vi': 'Lọc',
            'en': 'Filter'
        },
        'Price': {
            'vi': 'Giá',
            'en': 'Price'
        },
        'Login': {
            'vi': 'Đăng nhập',
            'en': 'Login'
        },
        'Username': {
            'vi': 'Tên đăng nhập',
            'en': 'Username'
        },
        'Password': {
            'vi': 'Mật khẩu',
            'en': 'Password'
        },
        'Painting': {
            'vi': 'Tranh',
            'en': 'Painting'
        },
        'User': {
            'vi': 'Người dùng',
            'en': 'User'
        },
        'Logout': {
            'vi': 'Đăng xuất',
            'en': 'Logout'
        },
        'Keyword': {
            'vi': 'Từ khóa',
            'en': 'Keyword'
        },
        'Picture name': {
            'vi': 'Tên tranh',
            'en': 'Picture name'
        },
        'Artist name': {
            'vi': 'Tên họa sĩ',
            'en': 'Artist name'
        },
        'Artwork': {
            'vi': 'Khổ giấy',
            'en': 'Artwork'
        },
        'Framed Artwork': {
            'vi': 'Khổ tranh',
            'en': 'Framed Artwork'
        },
        'Policy': {
            'vi': 'Điều khoản',
            'en': 'Policy'
        },
        'Close': {
            'vi': 'Đóng',
            'en': 'Close'
        },
        'Fullname': {
            'vi': 'Họ và tên',
            'en': 'Fullname'
        },
        'Email': {
            'vi': 'Email',
            'en': 'Email'
        },
        'Phone number': {
            'vi': 'Số điện thoại',
            'en': 'Phone number'
        },
        'Content': {
            'vi': 'Nội dung',
            'en': 'Content'
        },
        'Send': {
            'vi': 'Gửi',
            'en': 'Send'
        },
        'FREQUENTLY ASKED QUESTIONS': {
            'vi': 'CÁC CÂU HỎI THƯỜNG GẶP',
            'en': 'FREQUENTLY ASKED QUESTIONS'
        },
        'Terms of use': {
            'vi': 'Điều khoản sử dụng',
            'en': 'Terms of use'
        },
        'Privacy policy': {
            'vi': 'Chính sách bảo mật',
            'en': 'Privacy policy'
        },
        'Stocking': {
            'vi': 'Tranh chưa bán',
            'en': 'Stocking'
        },
        'All Painting': {
            'vi': 'Tất cả',
            'en': 'All Painting'
        },
        'Confirm Password': {
            'vi': 'Xác nhận mật khẩu',
            'en': 'Confirm Password'
        },
        'Register': {
            'vi': 'Đăng ký',
            'en': 'Register'
        },
        'Don\'t have an account? ': {
            'vi': 'Không có tài khoản? ',
            'en': 'Don\'t have an account? '
        },
        'Already have an acount? ': {
            'vi': 'Đã tài khoản? ',
            'en': 'Already have an acount? '
        },
        'Cart': {
            'vi': 'Giỏ hàng',
            'en': 'Cart'
        },
        'Price: ': {
            'vi': 'Giá: ',
            'en': 'Price: '
        },
        'Checkout': {
            'vi': 'Thanh toán',
            'en': 'Checkout'
        },
        'Customer information': {
            'vi': 'Thông tin khách hàng',
            'en': 'Customer information'
        },
        'Address': {
            'vi': 'Địa chỉ',
            'en': 'Address'
        },
        'Total: ': {
            'vi': 'Tổng thanh toán: ',
            'en': 'Total: '
        },
        'Confirm and checkout': {
            'vi': 'Xác nhận và thanh toán',
            'en': 'Confirm and checkout'
        },
        'Continue shopping': {
            'vi': 'Tiếp tục mua hàng',
            'en': 'Continue shopping'
        },
        'Shipping method': {
            'vi': 'Phương thức nhận hàng',
            'en': 'Shipping method'
        },
        'Self-collection at store': {
            'vi': 'Lấy hàng tại cửa hàng',
            'en': 'Self-collection at store'
        },
        'Delivery in 3-5 days. Fee: 20,000 - 50,000': {
            'vi': 'Giao hàng sau 3-5 ngày. Phí từ 20,000 đến 50,000',
            'en': 'Delivery in 3-5 days. Fee: 20,000 - 50,000'
        },
        'Coupon': {
            'vi': 'Mã khuyến mại',
            'en': 'Coupon'
        },
        'Enter your coupon': {
            'vi': 'Nhập mã khuyến mại',
            'en': 'Enter your coupon'
        },
        'Packaging & Delivery': {
            'vi': 'Đóng gói & vận chuyển',
            'en': 'Packaging & Delivery'
        },
        'Instruction': {
            'vi': 'Hướng dẫn mua hàng',
            'en': 'Instruction'
        },
        'National Delivery': {
            'vi': 'Khu vực trong nước',
            'en': 'National Delivery'
        },
        'Remember password': {
            'vi': 'Lưu mật khẩu',
            'en': 'Remember password'
        },
        'You are viewing 10 products on 27 products': {
            'vi': 'Đã xem 10 sản phẩm trên tổng số 27 sản phẩm',
            'en': 'You are viewing 10 products on 27 products'
        },
        'Load more': {
            'vi': 'Xem thêm',
            'en': 'Load more'
        },
        'I read and agreed with ': {
            'vi': 'Tôi đã đọc đồng ý với các ',
            'en': ' read and agreed with '
        },
        'Complete order': {
            'vi': 'Hoàn tất đơn hàng',
            'en': 'Complete order'
        },
        'Apply': {
            'vi': 'Áp dụng',
            'en': 'Apply'
        },
        'Total:': {
            'vi': 'Tổng:',
            'en': 'Total:'
        },
        'Coupon:': {
            'vi': 'Giảm giá:',
            'en': 'Coupon:'
        },
        'Total to pay:': {
            'vi': 'Tổng phải trả:',
            'en': 'Total to pay:'
        },
        'LOGIN': {
            'vi': 'ĐĂNG NHẬP',
            'en': 'LOGIN'
        },
        'REGISTER': {
            'vi': 'ĐĂNG KÝ',
            'en': 'REGISTER'
        },
        'terms of use': {
            'vi': 'điều khoản',
            'en': 'terms of use'
        },
        'CONTACT US': {
            'vi': 'LIÊN HỆ',
            'en': 'CONTACT US'
        },
        'Artist': {
            'vi': 'Tác giả',
            'en': 'Artist'
        },
        'ARTIST': {
            'vi': 'TÁC GIẢ',
            'en': 'ARTIST'
        },
        'SEARCH': {
            'vi': 'TÌM KIẾM',
            'en': 'SEARCH'
        },
        'Price from': {
            'vi': 'Từ:',
            'en': 'From'
        },
        'to': {
            'vi': 'đến:',
            'en': 'to'
        },
    }

    const cookies = new Cookies();
    var language : string | undefined = cookies.get(CookieKey.language)
    language = language === undefined ? 'vi' : language;
    try {
        return wordData[key as keyof typeof wordData][language] === undefined ? '' : wordData[key as keyof typeof wordData][language];
    }
    catch {
        return key + ' error'
    }
    
}