import { makeAutoObservable } from "mobx";

class ShopSiteMobx {
    modal: boolean = false
    image: string = ''
    paintings: Array<Painting> = [
        {
            name: 'Picture1',
            url: '/img/picture1.jpg',
            isSold: false
        },
        {
            name: 'Picture2',
            url: '/img/picture2.jpg',
            isSold: true
        },
        {
            name: 'Picture3',
            url: '/img/picture3.jpg',
            isSold: false
        },
        {
            name: 'Picture4',
            url: '/img/picture4.jpg',
            isSold: true
        },
        {
            name: 'Picture5',
            url: '/img/picture5.jpg',
            isSold: true
        },
        {
            name: 'Picture6',
            url: '/img/picture6.jpg',
            isSold: false
        },
        {
            name: 'Picture7',
            url: '/img/picture7.jpg',
            isSold: true
        },
        {
            name: 'Picture8',
            url: '/img/picture3.jpg',
            isSold: false
        },
        {
            name: 'Picture9',
            url: '/img/picture2.jpg',
            isSold: false
        },
        {
            name: 'Picture10',
            url: '/img/picture1.jpg',
            isSold: false
        },
        {
            name: 'Picture11',
            url: '/img/picture6.jpg',
            isSold: false
        },
        {
            name: 'Picture12',
            url: '/img/picture7.jpg',
            isSold: true
        },
    ]
    displays: Array<DisplayPainting> = []
    type: number = 0
    
    constructor() {
        makeAutoObservable(this)
    }
    fetchDisplay = () => {
        this.displays = getDisplayPainting(this.paintings)
    }
    changeView = (type: number) => {
        let items =  this.paintings
        if(type === 1) {
            items = this.paintings.filter((e) => e.isSold === false)
        }
        this.displays = getDisplayPainting(items)
    }
    showModal = (image: string) => {
        // console.log('MODAL SHOW')
        this.image = image
        this.modal = true
    }
    hideModal = () => {
        this.image = ''
        this.modal = false
    }
}
const ShopSiteStore = new ShopSiteMobx()
export default ShopSiteStore

interface Painting {
    name: string,
    url: string,
    isSold: boolean
}

interface DisplayPainting {
    rowId: number,
    rowItem: number,
    items: Array<Painting>
}

function getDisplayPainting(paintings : Array<Painting>) {
    let result : Array<DisplayPainting> = []
    let index: number = 0
    for(let i = 0; i < paintings.length; i += 3) {
        if(paintings[i] !== undefined) {
            let rowItem : DisplayPainting = {
                rowId: ++index,
                rowItem: 3,
                items: [
                    {
                        name: paintings[i].name,
                        url: paintings[i].url,
                        isSold: paintings[i].isSold,
                    }
                ]
            }
            if(paintings[i+1] !== undefined) {
                rowItem.items.push({
                    name: paintings[i+1].name,
                    url: paintings[i+1].url,
                    isSold: paintings[i+1].isSold,
                })
            }
            if(paintings[i+2] !== undefined) {
                rowItem.items.push({
                    name: paintings[i+2].name,
                    url: paintings[i+2].url,
                    isSold: paintings[i+2].isSold,
                })
            }
            result.push(rowItem)
        }
        // if(paintings[i+2] !== undefined) {
        //     let rowItem : DisplayPainting = {
        //         rowId: ++index,
        //         rowItem: 3,
        //         items: [
        //             {
        //                 name: paintings[i+2].name,
        //                 url: paintings[i+2].url,
        //                 isSold: paintings[i+2].isSold,
        //             }
        //         ]
        //     }
        //     if(paintings[i+3] !== undefined) {
        //         rowItem.items.push({
        //             name: paintings[i+3].name,
        //             url: paintings[i+3].url,
        //             isSold: paintings[i+3].isSold,
        //         })
        //     }
        //     if(paintings[i+4] !== undefined) {
        //         rowItem.items.push({
        //             name: paintings[i+4].name,
        //             url: paintings[i+4].url,
        //             isSold: paintings[i+4].isSold,
        //         })
        //     }
        //     result.push(rowItem)
        // }
    }
    return result;
}