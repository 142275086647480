import React from 'react'
import t from '../../Languages/LanguageTranslator'

export class Login extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <div className='login-layout'>
                <div className='login-card'>
                    <div className='login-card-header'>
                        <img src='/logo.png' alt='main-logo' />
                        <h4>{t('Login')}</h4>
                    </div>
                    <div className='login-card-body'>
                        <input placeholder={t('Username')} />
                        <hr />
                        <input type='password' placeholder={t('Password')} />
                    </div>
                    <div className='login-card-footer'>
                        <button onClick={() => { window.location.href = '/admin/painting' }}>{t('Login')}</button>
                    </div>
                </div>
            </div>
        </>
    }
}