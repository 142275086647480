import React from 'react'

export class PaintingItem extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <div className='col-lg-4 col-sm-6 col-12 mb-4'>
                <div className='painting-card-item'>
                    <div className='painting' style={{backgroundImage: "url('/img/picture1.jpg')"}}></div>
                    <div className='info'>
                        <div>
                            <h5>Painting name 1</h5>
                            <p>Tranh dầu</p>
                            <p>Họa sĩ: Marvelous Artist</p>
                            <p>Khổ giấy: 0.6x1.2m</p>
                            <p>Khung tranh: 0.7x1.3m</p>
                            <p>Trạng thái: <b>Còn hàng</b></p>
                        </div>
                        <div className='action-bar'>
                            <button className='custom-button warning'>Sửa</button>
                            <button className='custom-button danger'>Xóa</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}