import React from 'react'
import UserLayout from '../Layouts/UserLayout'
import t from '../../Languages/LanguageTranslator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons'
import './FaQ.jsx'

export class FaQ extends React.Component<{}, {temp: Array<number>}> {
    constructor(props: any) {
        super(props)
        this.state = {
            temp: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
        }
    }
    render(): React.ReactNode {
        return <>
            <UserLayout active={'faq'}>
                <h3 className='text-center mb-4'><b>{t('FREQUENTLY ASKED QUESTIONS')}</b></h3>
                <div className='row justify-content-center'>
                    {this.state.temp.map((e) => {
                        return <>
                            <div className='col-lg-7 mb-4 mt-4'>
                                <div className='faq-item'>
                                    <h4><FontAwesomeIcon icon={faAngleRight} className='icon' /> Question Number {e}</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                                </div>
                            </div>
                        </>
                    })}
                </div>
            </UserLayout>
            {/* <div className='back-to-home' onClick={() => {
                window.location.href = '/'
            }}>
                <FontAwesomeIcon icon={faHome} />
            </div> */}
        </>
    }
}