import React from 'react'
import UserLayout from '../Layouts/UserLayout'
import t from '../../Languages/LanguageTranslator'

export class Instruction extends React.Component<{}, {temp: Array<number>}> {
    render(): React.ReactNode {
        return <>
        <UserLayout active={'tou'}>
            <div className='contact-us-wrapper'>
                <div className='contact-us-left'></div>
                <div className='contact-us-right'>
                    <p className='long-paragraph'>
                        <h3 className='text-center mb-4'><b>{t('Instruction')}</b></h3>
                        <p>Qúy khách có thể đặt mua sản phẩm yêu thích tại website chính thức của Công ty Thái Công chỉ với 4 bước đơn giản sau:</p>
                        <h5>BƯỚC 1:</h5>
                        <p>Truy cập website <a href='https://store.qframe.vn'>store.qframe.vn</a></p>
                        <h5>BƯỚC 2:</h5>
                        <p>Chọn sản phẩm phù hợp, tham khảo thông tin sản phẩm, click Mua ngay để thêm vào giỏ hàng</p>
                        <h5>BƯỚC 3:</h5>
                        <p>Nhập thông tin giao hàng, chọn phương thức thanh toán</p>
                        <h5>BƯỚC 4:</h5>
                        <p>QFrame sẽ liên hệ tới bạn</p>
                    </p>
                </div>
            </div>
        </UserLayout>
        </>
    }
}