import { faCartPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import ShopSiteStore from '../ShopSiteMobx'
import Slider from "react-slick";
// import $ from 'jquery'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    // dotsClass: 'popup-painting-dot'
  };
export class StoreModal extends React.Component<{children: ReactNode, active: boolean}> {
    render(): React.ReactNode {
        console.log('url("'+ ShopSiteStore.image+'")')
        return <>
            <div className={'store-modal-layout' + (this.props.active ? ' active' : '')} onMouseDown={() => {
                ShopSiteStore.hideModal()
            }}>
                <div className='store-modal'>
                    <div className='store-modal-left' onMouseDown={(e) => {
                        stopDefault(e)
                    }}>
                        <h3>CÁNH ĐÀO ĐẦU XUÂN</h3>
                        <hr />
                        <p><b>Đặng Kim Long</b></p>
                        <p>122x 244 cm</p>
                        <p><i><b>Hội xuân</b></i>, 2019</p>
                        <p>Tranh dầu</p>
                        <br></br>
                        <p><b>200.000<sup>đ</sup></b>&emsp;<FontAwesomeIcon icon={faCartPlus} className='icon' /></p>
                    </div>
                    <div className='store-modal-center' onMouseDown={(e) => {
                        stopDefault(e)
                    }}>
                        <div style={{width: '100%', height: '100%'}}>
                            <Slider {...settings}>
                                <div className='store-modal-image'></div>
                                <div className='store-modal-image'></div>
                                <div className='store-modal-image'></div>
                            </Slider>
                        </div>
                    </div>
                    <div className='store-modal-right' onMouseDown={(e) => {
                        stopDefault(e)
                    }}>
                        <p>What is Lorem Ipsum?
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
        </>
    }
}

function stopDefault(e : React.MouseEvent) {
    e.stopPropagation();
}