import React, { ReactNode } from 'react';

export class LayoutMainContent extends React.Component<{children: ReactNode}> {
    render(): React.ReactNode {
        return <>
            <div className='main-content'>
                {this.props.children}
            </div>
        </>
    }
}