import { faHome, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import React from 'react'
import $ from 'jquery'
import t from '../../../Languages/LanguageTranslator'
import Cookies from 'universal-cookie'
import { CookieKey } from '../../../constants/CookieKey'

export class ExtraMenu extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <div className='extra-menu-wrapper'>
                <div className='extra-menu-left'>
                    <div className='extra-menu-left-top'>
                        <img src='/img/extra-menu-image.jpg' alt='' />
                        <p>“Simplicity is the Ultimate Sophistication”</p>
                        <p>— Leonardo Da Vinci</p>
                    </div>
                    <div className='extra-menu-left-bottom'>
                        <div className='extra-menu-left-bottom-right'>
                            <a href='/terms-of-use'>{t('Terms of use')}</a>
                            <br />
                            <a href='/privacy-policy'>{t('Privacy policy')}</a>
                        </div>
                        <div className='extra-menu-left-bottom-center'>
                            <a href='https://www.facebook.com/qframe.official' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faFacebook}  className='icon' /></a>
                            <a href='https://www.instagram.com/qframe.official/' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram}  className='icon' /></a>
                            <a href='/' ><FontAwesomeIcon icon={faHome}  className='icon' /></a>
                        </div>
                        <div className='extra-menu-left-bottom-left'>
                            <a href='/packaging-and-delivery'>{t('Packaging & Delivery')}</a>
                            <br />
                            <a href='/instruction'>{t('Instruction')}</a>
                        </div>
                    </div>
                </div>
                <div className='extra-menu-right'>
                    <div className='extra-menu-right-top'>
                        <a href=''>DANH MỤC GÌ ĐÓ</a>
                        <a href=''>DANH MỤC GÌ ĐÓ</a>
                        <a href=''>DANH MỤC GÌ ĐÓ</a>
                        <a href=''>DANH MỤC GÌ ĐÓ</a>
                        <a href='#'>{t('ARTIST')}</a>
                        <a href='/contact-us'>{t('CONTACT US')}</a>
                    </div>
                    <div className='extra-menu-right-bottom'>
                        <div onClick={() => {
                            const cookies = new Cookies()
                            cookies.set(CookieKey.language, 'vi')
                            window.location.href = ''
                        }}>VI</div>&emsp;|&emsp;<div onClick={() => {
                            const cookies = new Cookies()
                            cookies.set(CookieKey.language, 'en')
                            window.location.href = ''
                        }}>EN</div>
                    </div>
                </div>
                {/* <FontAwesomeIcon icon={faXmark} className='extra-menu-close-icon' onClick={() => {
                    $('.extra-menu-wrapper').removeClass('show')
                }} /> */}
            </div>
        </>
    }
}