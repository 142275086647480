import React from 'react'
import ShopSiteStore from '../ShopSiteMobx'

export class StoreItem extends React.Component<{url: string, name: string, isSold: boolean}> {
    render(): React.ReactNode {
        return <>
            <div className='store-item' onClick={() => {
                ShopSiteStore.showModal(this.props.url)
            }}>
                <div className='store-item-painting'>
                    <img width='100%' src={this.props.url} alt='' />
                </div>
                <p className='name d-flex align-items-center'>{this.props.name} {
                this.props.isSold ? <div style={{
                    color: '#fff', 
                    backgroundColor: 'red', 
                    padding: '5px', 
                    fontSize: '10px',
                    borderRadius: '100px', 
                    marginLeft: '5px',
                    width: '30px', 
                    height: '30px', 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transform: 'rotate(15deg)'
                }}><b>SOLD</b></div> : <></>
                } </p>
            </div>
        </>
    }
}