import React from 'react'
import UserLayout from '../Layouts/UserLayout'
import './ContactUs.jsx'
import t from '../../Languages/LanguageTranslator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

export class ContactUs extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <UserLayout active={'contact-us'}>
                <div className='contact-us-wrapper'>
                    <div className='contact-us-left'></div>
                    <div className='contact-us-right'>
                        <p className='long-paragraph' style={{padding: '0px'}}>
                            <h6><b>QFRAME</b></h6>
                            <p style={{marginBottom: '50px'}}>Thành viên Công ty TNHH MTV Khung Đẹp</p>
                            <h6><b>SHOWROOM</b></h6>
                            <p style={{marginBottom: '50px'}}>
                                38A Đinh Tiên Hoàng, Phường Đakao, Quận 1<br />
                                Thành phố Hồ Chí Minh, Việt Nam
                            </p>
                            <p style={{marginBottom: '10px'}}>T: (+84) 986 96 1001</p>
                            <p>E: enquiry@frame.vn</p>
                        </p>
                        <div className='row contact-form'>
                            <div className='col-6 d-flex flex-column justify-content-between'>
                                <input placeholder={t('Fullname')} />
                                <input placeholder={t('Email')} />
                                <input placeholder={t('Phone number')} />
                            </div>
                            <div className='col-6'>
                                <textarea placeholder={t('Content')}></textarea>
                            </div>
                            <div className='col-12 text-end mt-3'>
                                <button className='custom-button'>{t('Send')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </UserLayout>
            {/* <div className='back-to-home' onClick={() => {
                window.location.href = '/'
            }}>
                <FontAwesomeIcon icon={faHome} />
            </div> */}
        </>
    }
}