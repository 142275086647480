import React from 'react'
import UserLayout from '../Layouts/UserLayout'
import t from '../../Languages/LanguageTranslator'

export class PackagingDelivery extends React.Component<{}, {temp: Array<number>}> {
    render(): React.ReactNode {
        return <>
        <UserLayout active={'tou'}>
            <div className='contact-us-wrapper'>
                <div className='contact-us-left'></div>
                <div className='contact-us-right'>
                    <p className='long-paragraph'>
                        <h3 className='text-center mb-4'><b>{t('Packaging & Delivery')}</b></h3>
                        <h5>&bull; Khu vực trong nước</h5>
                        <h6>1. Khu vực áp dụng và đơn vị vận chuyển:</h6>
                        <p>Áp dụng cho tất cả địa chỉ giao hàng trong lãnh thổ Việt Nam</p>
                        <h6>2. Thời gian giao hàng:</h6>
                        <p>Khi hoàn tất với bên giao hàng, QFrame sẽ thông báo đến quý khách thời gian giao hàng dự kiến và phí ship của sản phẩm đến khách hàng.</p>
                        <i>*Lưu ý: Thời gian giao hàng trên chỉ mang tính dự kiến, thực tế có thể dao động sớm hoặc muộn hơn tùy theo sản phẩm quý khách đặt mua.</i>
                        <h5>&bull; Khu vực nước ngoài</h5>
                        <h6>1. Khu vực áp dụng và đơn vị vận chuyển:</h6>
                        <p>Áp dụng cho tất cả địa chỉ giao hàng trên toàn cầu với các đơn vị vận chuyển quốc tế như: FedEx, DHL, UPS,…</p>
                        <h6>2. Thời gian giao hàng:</h6>
                        <p>Thời gian vận chuyển phụ thuộc vào kích thước đơn hàng và địa chỉ nhận hàng. VY sẽ liên hệ với quý khách để thông báo về thời gian giao hàng dự kiến và phí ship sản phẩm cụ thể, sau khi hoàn tất đơn hàng.</p>
                        <i>*Lưu ý: Thời gian giao hàng trên chỉ mang tính dự kiến, thực tế có thể dao động sớm hoặc muộn hơn tùy theo sản phẩm quý khách đặt mua.</i>
                        <h6>3. Trong quá trình vận chuyển nếu xảy ra hư hại, quy trình xử lý sẽ diễn ra như thế nào ?</h6>
                        <p>Trong trường hợp không mong muốn xảy ra, quý khách vui lòng liên lạc với VY qua số hotline +84 hoặc gửi email cho chúng tôi theo địa chỉ: info@qframe.com. Chúng tôi sẽ liên lạc với bạn ngay khi nhận được yêu cầu.</p>
                    </p>
                </div>
            </div>
        </UserLayout>
        </>
    }
}