import { makeAutoObservable } from "mobx";
import t from '../../Languages/LanguageTranslator' 
import $ from 'jquery'

class UserLayoutMobx {
    constructor() {
        makeAutoObservable(this)
    }

    titleMenu : string  = t('All Painting')

    searchModalActive : boolean = false
    priceFrom : string = ''
    priceTo : string = ''

    onTitleChange = (text: string) => {
        this.titleMenu = text
        setTimeout(function() {
            $('.center-header-content').css('left', `${($(window).width()! - $('.center-header-content').width()!) / 2}px`)
        }, 1)
    }

    toggleSearchModal  = () => {
        $('.search-modal-wrapper').addClass('active')
    }

    onPriceFromChange = (text: string) => {
        if(text.length <= 8) {
            this.priceFrom = text
        }
    }
    onPriceToChange = (text: string) => {
        if(text.length <= 8) {
            this.priceTo = text
        }
    }
}

export const UserLayoutStore = new UserLayoutMobx()