import React from 'react'
import t from '../../Languages/LanguageTranslator'
import UserLayout from '../Layouts/UserLayout'
import './UserLogin.jsx'

export class UserLogin extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <UserLayout active={'auth'}>
                <div className='auth-page-wrapper'>
                    <div className='auth-page-left'>
                        <h3>{t('LOGIN')}</h3>
                        <input placeholder={`${t('Email')}*`} />
                        <input placeholder={`${t('Password')}*`} type='password' />
                        <div className='mt-3'><input type='checkbox' /><span>{t('Remember password')}</span><br /></div>
                        <button className='custom-button mb-4' onClick={() => {
                            sessionStorage.setItem('logined', 'true')
                            window.location.href = '/'
                        }}>{t('LOGIN')}</button> <p className='mt-2'>Quên mật khẩu? <a href=''>Click vào đây</a></p>
                    </div>
                    <div className='auth-page-right'>
                        <h3>{t('REGISTER')}</h3>
                        <input placeholder={`${t('Fullname')}*`} />
                        <input placeholder={`${t('Password')}*`} type='password' />
                        <input placeholder={`${t('Confirm Password')}*`} type='password' />
                        <input placeholder={`${t('Email')}*`} />
                        <input placeholder={t('Phone number')} />
                        <button className='custom-button'>{t('REGISTER')}</button>
                    </div>
                </div>
            </UserLayout>
        </>
    }
}