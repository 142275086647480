import React from "react";

export class CartItemCount extends React.Component<{amount: number}> {
    render(): React.ReactNode {
        return (
            <div className="cart-item-count" onClick={() => {
                window.location.href = '/cart'
            }} >
                {this.props.amount}
            </div>
        )
    }
}