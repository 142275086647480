import React from 'react'
import UserLayout from '../Layouts/UserLayout'
import t from '../../Languages/LanguageTranslator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { observer } from 'mobx-react'
import { CartStore } from './CartMobx'

class Cart extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <UserLayout active={'cart'}>
                <div className='cart-page-wrapper'>
                    <div className='cart-page-form'>
                        <h3>{t('Customer information')}</h3>
                        {
                            sessionStorage.getItem('logined') !== null && !CartStore.changeFullname ? <>
                                <p className='d-flex mb-4'><h5><b>{t('Fullname')}:</b></h5>&ensp;Vũ Mạnh - <a href='#' onClick={() => {
                                    CartStore.enableChangeFullname()
                                }}>Thay đổi</a></p>
                            </> : <>
                                <input type='text' placeholder={`${t('Fullname')}*`} />
                            </>
                        }
                        {
                            sessionStorage.getItem('logined') !== null && !CartStore.changePhone ? <>
                                <p className='d-flex mb-4'><h5><b>{t('Phone number')}:</b></h5>&ensp;0956325412 - <a href='#' onClick={() => {
                                    CartStore.enableChangePhone()
                                }}>Thay đổi</a></p>
                            </> : <>
                                <input type='text' placeholder={`${t('Phone number')}*`} />
                            </>
                        }
                        <input type='text' placeholder={`${t('Address')}*`} />
                        <h3>{t('Shipping method')}</h3>
                        <input type='radio' name='shippingMethod' />&emsp;{t('Self-collection at store')}<br />
                        <input type='radio' name='shippingMethod' />&emsp;{t('Delivery in 3-5 days. Fee: 20,000 - 50,000')}<br />
                        <hr></hr>
                        <input type='checkbox' /> &emsp;{t('I read and agreed with ')}<a href='/terms-of-use' target='_blank'>{t('terms of use')}</a><br />
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-between'>
                                <button className='custom-button secondary' onClick={() => {
                                    window.location.href = '/'
                                }}>{t('Continue shopping')}</button>
                                <button className='custom-button'>{t('Complete order')}</button>
                            </div>
                        </div>
                    </div>
                    <div className='cart-page-items'>
                        <div className='cart-item-list'>
                            <div className='cart-item'>
                                <div className='cart-item-left'>
                                    <div className='cart-item-image' style={{backgroundImage: 'url(/img/picture1.jpg)'}}></div>
                                    <h4>Picture name 1</h4>
                                </div>
                                <b>1,234,567đ</b>
                            </div>
                            <div className='cart-item'>
                                <div className='cart-item-left'>
                                    <div className='cart-item-image' style={{backgroundImage: 'url(/img/picture1.jpg)'}}></div>
                                    <h4>Picture name 1</h4>
                                </div>
                                <b>1,234,567đ</b>
                            </div>
                            <div className='cart-item'>
                                <div className='cart-item-left'>
                                    <div className='cart-item-image' style={{backgroundImage: 'url(/img/picture1.jpg)'}}></div>
                                    <h4>Picture name 1</h4>
                                </div>
                                <b>1,234,567đ</b>
                            </div>
                            <div className='cart-item'>
                                <div className='cart-item-left'>
                                    <div className='cart-item-image' style={{backgroundImage: 'url(/img/picture1.jpg)'}}></div>
                                    <h4>Picture name 1</h4>
                                </div>
                                <b>1,234,567đ</b>
                            </div>
                            <div className='cart-item'>
                                <div className='cart-item-left'>
                                    <div className='cart-item-image' style={{backgroundImage: 'url(/img/picture1.jpg)'}}></div>
                                    <h4>Picture name 1</h4>
                                </div>
                                <b>1,234,567đ</b>
                            </div>
                        </div>
                        <div className='coupon-section'>
                            <input placeholder={t('Coupon')} />
                            <button className='custom-button'>{t('Apply')}</button>
                        </div>
                        <hr />
                        <p><p>{t('Total:')}</p> <b>1,234,567đ</b></p>
                        <p><p>{t('Coupon:')}</p> <b>1,234,567đ</b></p>
                        <hr />
                        <p><p>{t('Total to pay:')}</p> <b>1,234,567đ</b></p>
                    </div>
                </div>
            </UserLayout>
        </>
    }
}

export default observer(Cart)