import React from 'react'
import { AdminLayout } from '../Layouts/AdminLayout'
import { PaintingItem } from './Components/PaintingItem'
import t from '../../Languages/LanguageTranslator'

export class Pictures extends React.Component<{}> {
    render(): React.ReactNode {
        return <>
            <AdminLayout active='painting'>
                <div className='row mb-4'>
                    <div className='col-lg-3'>
                        <input className='custom-input' placeholder={t('Picture name')} />
                    </div>
                    <div className='col-lg-3'>
                        <input className='custom-input' placeholder={t('Artist name')} />
                    </div>
                    <div className='col-lg-3'>
                        <input className='custom-input' placeholder={t('Artwork')} />
                    </div>
                    <div className='col-lg-3'>
                        <input className='custom-input' placeholder={t('Framed Artwork')} />
                    </div>
                </div>
                <div className='row'>
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                    <PaintingItem />
                </div>
            </AdminLayout>
        </>
    }
}