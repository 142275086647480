import { makeAutoObservable } from "mobx";

class CartMobx {
    constructor() {
        makeAutoObservable(this)
    }

    changeFullname : boolean = false
    changePhone : boolean = false

    enableChangeFullname = () => {
        this.changeFullname = true
    }
    enableChangePhone = () => {
        this.changePhone = true
    }
}

export const CartStore = new CartMobx()