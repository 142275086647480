import $ from 'jquery'

var maxRow = 4
$(document).ready( function()  {
    //var screenHeight = $(window).height()
    // var items = $('.store-item-row').serializeArray()
    // $.each(items, function() {
    //         $(this).css('height', `${screenHeight}px`)
    //         console.log($("body"))
    //         $("body").addClass("dummyClass").removeClass("dummyClass");
    //         console.log($("body"))
    // })
    // $('.store-item-row').each(function(e) {
    //     $(this).css('height', `${screenHeight}px`)
    //     console.log($("body"))
    //     $("body").addClass("dummyClass").removeClass("dummyClass");
    //     console.log($("body"))
    // })
    var shopPage = true
    var abc = $('#root').find('header.fixed')
    if(parseInt(abc.length) !== 0) {
        shopPage = false
    }
    if(shopPage === true) {
        var prevFromTopPos = 0;
        var currentRow = 1
        var scrolling = false
          $('#root').on('scroll', function() {
            var fromTopPos = $('#root').scrollTop()
            //On scroll down
            if(!scrolling) {
                // console.log(fromTopPos + ' ' + prevFromTopPos)
                if(fromTopPos > prevFromTopPos) {
                    prevFromTopPos = fromTopPos
                    // console.log('RUNNING DOWN')
                    $('header').removeClass('show')
                    //Scroll to next image row
                    if(currentRow < maxRow) {
                        prevFromTopPos = sumPreviousHeight(currentRow, true)
                        scrolling = true;
                        // console.log('SET TRUE')
                        $('#root').animate({'scrollTop' : sumPreviousHeight(currentRow, true)}, {
                            duration: 1500,
                            complete: function() {
                                scrolling = false;
                                // console.log('SET FALSE')
                            }
                        })
                        currentRow++
                        updateRowNav(currentRow)
                        if(currentRow === maxRow) {
                            $('footer').addClass('show')
                            $('.bottom-fade').addClass('hide')
                        }
                    }
                }
                //On scroll up
                else if (fromTopPos < prevFromTopPos) {
                    prevFromTopPos = fromTopPos
                    // console.log('RUNNING UP')
                    $('header').addClass('show')
                    if(currentRow > 1) {
                        prevFromTopPos = sumPreviousHeight(currentRow - 2, false)
                        scrolling = true;
                        $('#root').animate({'scrollTop' : sumPreviousHeight(currentRow - 2, false)}, {
                            duration: 1500,
                            complete: function() {
                                scrolling = false;
                                // console.log('SET FALSE')
                            }
                        })
                        currentRow--
                        updateRowNav(currentRow)
                        $('footer').removeClass('show')
                        $('.bottom-fade').removeClass('hide')
                    }
                }
            }
          })
          $('#btnNavDown').on('click', function() {
            if(!scrolling) {
                if(currentRow < maxRow) {
                    prevFromTopPos = sumPreviousHeight(currentRow, true)
                        scrolling = true;
                        // console.log('SET TRUE')
                        $('header').removeClass('show')
                        $('#root').animate({'scrollTop' : sumPreviousHeight(currentRow, true)}, {
                            duration: 1500,
                            complete: function() {
                                scrolling = false;
                                // console.log('SET FALSE')
                            }
                        })
                        currentRow++
                        updateRowNav(currentRow)
                        if(currentRow === maxRow) {
                            $('footer').addClass('show')
                            $('.bottom-fade').addClass('hide')
                        }
                }
            }
          })
          $('#btnNavUp').on('click', function() {
            if(!scrolling) {
                if(currentRow > 1) {
                    prevFromTopPos = sumPreviousHeight(currentRow - 2, false)
                    $('header').addClass('show')
                    scrolling = true;
                    $('#root').animate({'scrollTop' : sumPreviousHeight(currentRow - 2, false)}, {
                        duration: 1500,
                        complete: function() {
                            scrolling = false;
                            // console.log('SET FALSE')
                        }
                    })
                    currentRow--
                    updateRowNav(currentRow)
                    $('footer').removeClass('show')
                    $('.bottom-fade').removeClass('hide')
                }
            }
          })
          $('#root').on('keypress', function(e) {
            switch(e.key) {
                case 38:
                    if(!scrolling) {
                        if(currentRow > 1) {
                            prevFromTopPos = sumPreviousHeight(currentRow - 2, false)
                            scrolling = true;
                            $('header').addClass('show')
                            $('#root').animate({'scrollTop' : sumPreviousHeight(currentRow - 2, false)}, {
                                duration: 1500,
                                complete: function() {
                                    scrolling = false;
                                    // console.log('SET FALSE')
                                }
                            })
                            currentRow--
                            updateRowNav(currentRow)
                            $('footer').removeClass('show')
                            $('.bottom-fade').removeClass('hide')
                        }
                    }
                    break;
                case 39:
                    if(!scrolling) {
                        if(currentRow < maxRow) {
                            prevFromTopPos = sumPreviousHeight(currentRow, true)
                            scrolling = true;
                            // console.log('SET TRUE')
                            $('header').removeClass('show')
                            $('#root').animate({'scrollTop' : sumPreviousHeight(currentRow, true)}, {
                                duration: 1500,
                                complete: function() {
                                    scrolling = false;
                                    // console.log('SET FALSE')
                                }
                            })
                            currentRow++
                            updateRowNav(currentRow)
                            if(currentRow === maxRow) {
                                $('footer').addClass('show')
                                $('.bottom-fade').addClass('hide')
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
          })
          $('.store-nav-column .dot').on('click', function() {
            var clickedIndex = parseInt($(this).attr('data-row'))
            if(!scrolling) {
                prevFromTopPos = sumPreviousHeight(clickedIndex - 1, clickedIndex > currentRow)
                scrolling = true;
                $('header').addClass('show')
                // console.log('SET TRUE')
                $('#root').animate({'scrollTop' : sumPreviousHeight(clickedIndex - 1, clickedIndex > currentRow)}, {
                    duration: 1500,
                    complete: function() {
                        scrolling = false;
                        // console.log('SET FALSE')
                    }
                })
                currentRow = clickedIndex
                updateRowNav(clickedIndex)
                if(currentRow === maxRow) {
                    $('footer').addClass('show')
                    $('.bottom-fade').addClass('hide')
                }
                else {
                    $('footer').removeClass('show')
                    $('.bottom-fade').removeClass('hide')
                }
            }
          })
    }
    
  })


function sumPreviousHeight(limit, isDown) {
    var x = 0
    for(let i = 1; i<=limit; i++) {
        x += $(window).height()
    }
    return x  + 70 + (limit === (maxRow-1) ? 150 : 0);
}

function updateRowNav(currentIndex) {
    $('.store-nav-column .dot').each(function(e) {
        $(this).removeClass('active')
    });
    $('.store-nav-column .dot[data-row='+currentIndex+']').addClass('active')
}

export default function UpdateMaxRow(amount) {
    maxRow = amount
}