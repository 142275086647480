import { faBars, faFileLines, faImage, faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons'
import React, { ReactNode } from 'react'
import t from '../../Languages/LanguageTranslator'
import { LeftNavigationItem } from './Components/LeftNavigationItem'

export class AdminLayout extends React.Component<{children: ReactNode, active: string}> {
    render(): React.ReactNode {
        return <>
            <div className='admin-header'>
                <h1>QFrame Administration</h1>
            </div> 
            <div className='left-navigation'>
                <div className='left-navigation-header'>
                    <img src='/logo.png' alt='' />
                </div>
                <LeftNavigationItem icon={faImage} name={t('Painting')} active={this.props.active === 'painting'} />
                <LeftNavigationItem icon={faUser} name={t('User')} active={this.props.active === 'user'} />
                <LeftNavigationItem icon={faBars} name={t('Category')} active={this.props.active === 'category'} />
                <LeftNavigationItem icon={faFileLines} name={t('Policy')} active={this.props.active === 'policy'} />
                <LeftNavigationItem icon={faPowerOff} name={t('Logout')} active={this.props.active === 'logout'} />
            </div>
            <div className='admin-content'>
                {this.props.children}
            </div>
        </>
    }
}