import React from 'react';
// import t from '../../Languages/LanguageTranslator';
import UserLayout from '../Layouts/UserLayout';
import { StoreItem } from './Components/StoreItem';
import UpdateMaxRow from './ShopSite.jsx'
import './ShopSite.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import ShopSiteStore from './ShopSiteMobx';
import { StoreModal } from './Components/StoreModal';
import t from '../../Languages/LanguageTranslator'
import $ from 'jquery'

function getScreenHeight() : number {
    return $(window).height()!
}

class ShopSite extends React.Component<{}, {}> {
    constructor(props: any) {
        super(props)
        ShopSiteStore.fetchDisplay()
    }
    updateRow = (amount: number) => {
        new UpdateMaxRow(amount)
    }
    render(): React.ReactNode {
        return<>
            <UserLayout active={'shop'}>
                {
                    ShopSiteStore.displays.map((row) => {
                        return <>
                            <div id={'imageRow' + row.rowId} className='row justify-content-evenly align-items-center store-item-row'>
                                {
                                    row.items.map((item) => {
                                        return <>
                                            <div style={{height: `${getScreenHeight()}px`}} className={(row.rowItem === 2 ? 'col-lg-5' : 'col-lg-4') + ' col-12 store-item-wrapper'}>
                                                <StoreItem url={item.url} name={item.name.toUpperCase()} isSold={item.isSold} />
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </>
                    })
                }
                {/* <div className='store-page-nav'>
                    <div className='store-page-nav-item active'>1</div>
                    <div className='store-page-nav-item'>2</div>
                    <div className='store-page-nav-item'>3</div>
                    <div className='store-page-nav-item'>4</div>
                    <div className='store-page-nav-item'><FontAwesomeIcon icon={faAngleRight} /></div>
                </div> */}
                <div className='store-load-more'>
                    <p>{t('You are viewing 10 products on 27 products')}</p>
                    <button>{t('Load more')}</button>
                </div>
            </UserLayout>
            {/* <div className='bottom-fade'>

            </div> */}
            {/* <div id='btnNavUp' className='btn-row-image-navigate previous'>
                <FontAwesomeIcon icon={faAngleUp} color='#fff' />
            </div>
            <div id='btnNavDown' className='btn-row-image-navigate next'>
                <FontAwesomeIcon icon={faAngleDown} color='#fff' />
            </div> */}
            {/* <div className='store-nav-column'>
                {
                    ShopSiteStore.displays.map((e) => {
                        this.updateRow(ShopSiteStore.displays.length)
                        return <>
                            <div className={'dot' + (ShopSiteStore.displays.indexOf(e) === 0 ? ' active' : '')} data-row={ShopSiteStore.displays.indexOf(e)+1}></div>
                        </>
                    })
                }
            </div> */}
            <StoreModal active={ShopSiteStore.modal}>
                
            </StoreModal>
        </>
    }
}

export default observer(ShopSite)