import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import ShopSite from './Modules/Shop/ShopSite';
import React from "react";
import './css/base.css';
import './css/app.css';
import './css/admin_app.css';
import './css/extraMenuButton.scss';
import { Login } from "./ModulesAdmin/Authentication/Login";
import { Pictures } from "./ModulesAdmin/Pictures/Pictures";
import { ContactUs } from "./Modules/ContactUs/ContactUs";
import { FaQ } from "./Modules/FaQ/FaQ";
import { TermsOfUse } from "./Modules/TermsOfUse/TermsOfUse";
import { PrivacyPolicy } from "./Modules/PrivacyPoliccy/PrivacyPolicy";
import { UserLogin } from "./Modules/Auth/UserLogin";
import Cart from './Modules/Cart/Cart';
import { PackagingDelivery } from "./Modules/PackagingDelivery/PackagingDelivery";
import { Instruction } from "./Modules/Instruction/Instruction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ViChange } from "./Modules/Lang/Vi";
import { EnChange } from "./Modules/Lang/En";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to ="/404" />}/>
        <Route path="/" Component={ShopSite}/>
        <Route path="/contact-us" Component={ContactUs}/>
        <Route path="/faq" Component={FaQ}/>
        <Route path="/terms-of-use" Component={TermsOfUse}/>
        <Route path="/privacy-policy" Component={PrivacyPolicy}/>
        <Route path="/login-register" Component={UserLogin}/>
        <Route path="/cart" Component={Cart}/>
        <Route path="/packaging-and-delivery" Component={PackagingDelivery}/>
        <Route path="/instruction" Component={Instruction}/>
        <Route path="/vi" Component={ViChange}/>
        <Route path="/en" Component={EnChange}/>
        {/* Admin page */}
        <Route path="/admin" Component={Login} />
        <Route path="/admin/painting" Component={Pictures} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
