import React, { ReactNode } from 'react';
import t from '../../Languages/LanguageTranslator';
import { HDivider } from '../../components/VDivider';
import { CartItemCount } from './Components/CartItemCount';
import { LayoutMainContent } from './Components/LayoutMainContent';
import './UserLayout.jsx'
import $ from 'jquery'
import ShopSiteStore from '../Shop/ShopSiteMobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhone, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ExtraMenu } from './Components/ExtraMenu';
import { UserLayoutStore } from './UserLayoutMobx';
import { observer } from 'mobx-react';

function getLeftPos() : number {
    return ($(window).width()! - $('.center-header-content').width()!) / 2
}

class UserLayout extends React.Component<{children: ReactNode, active: String}, {}> {
    componentDidMount() {
        document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });
      };
    render(): React.ReactNode {
        return<>
            <header id='header' className={'show' + (this.props.active !== 'shop' ? ' fixed': '')}>
                <div className='left-header-content'>
                    <a href='/'><img className='logo' src='/logo.png' alt='logo' /></a>
                </div>
                <div className='center-header-content' style={{left: `${ getLeftPos()}px`}}>
                    {
                        this.props.active === 'shop' ? <>
                            <h3 id='selectCategory'>{UserLayoutStore.titleMenu}</h3>
                            <div className='category-dropdown'>
                                <a href=''>Danh mục 1</a>
                                <a href=''>Danh mục 2</a>
                                <a href=''>Danh mục 3</a>
                                <a href=''>Danh mục 4</a>
                            </div>
                        </> : <>
                            {/* <h3 onClick={() => {
                                window.location.href  = '/'
                            }} >{t('Continue shopping')}</h3> */}
                        </>
                    }
                </div>
                <div className='right-header-content'>
                    {
                        sessionStorage.getItem('logined') !== null ?
                        <><a href='#'>Vũ Mạnh</a>
                        </> : <>
                            <a href='/login-register'>{t('Login')}</a>&ensp;<p style={{color: 'var(--text-light)'}}>/</p>&ensp;<a href='/login-register'>{t('Register')}</a>
                        </>
                    }
                    
                    &emsp;
                    <HDivider />
                    <CartItemCount amount={0} />
                    {/*
                        this.props.active === 'shop' ? <></>
                        : <>
                            <div style={{width: '40px'}}>

                            </div>
                            {/* <FontAwesomeIcon icon={faBars} className='extra-menu-icon' onClick={() => {
                                $('.extra-menu-wrapper').addClass('show')
                            }} />  }
                        </>
                    */}
                    <FontAwesomeIcon icon={faSearch} className='search-icon' onClick={() => {
                        UserLayoutStore.toggleSearchModal()
                    }} />
                    <div style={{width: '40px'}}>

                    </div>
                </div>
                <div id='extraMenuButton' className="navTrigger" onClick={() => {
                        if($('#extraMenuButton').attr('class')?.includes('active')) {
                            $('#extraMenuButton').removeClass('active')
                            $('.extra-menu-wrapper').removeClass('show')
                        }
                        else {
                            $('#extraMenuButton').addClass('active')
                            $('.extra-menu-wrapper').addClass('show')
                        }
                    }}>
                        <i></i><i></i><i></i>
                    </div>
            </header>
            <LayoutMainContent>
                {this.props.children}
            </LayoutMainContent>
            {/* <footer className={this.props.active !== 'shop' ? 'show' : ''}>
                <div className='social-contact'>
                    <a href='https://www.facebook.com/qframe.official' target='_blank' rel='noreferrer'><img src='/icons/facebook.svg' alt='' height={19} /></a>
                    <a href='https://www.instagram.com/qframe.official/' target='_blank' rel='noreferrer'><img src='/icons/instagram.svg' alt='' height={23} /></a>
                    <a href='tel:+84968961001'><FontAwesomeIcon className='icon' icon={faPhone} /></a>
                </div>
                <a href='/contact-us'>{t('Contact Us')}</a>
                <a href='/faq'>FaQ</a>
                <a href='/terms-of-use'>{t('Terms of use')}</a>
                <a href='/privacy-policy'>{t('Privacy policy')}</a>
                <p className='copyright'>Copyright &copy; QFrame {new Date().getFullYear()}</p>
            </footer> */}
            <div className='select-category-dialog' onClick={(e) => {
                $('.select-category-dialog').removeClass('show')
            }}>
                {/* <a href='' onClick={(e) => {
                    e.preventDefault()
                    $('.select-category-dialog').removeClass('show')
                }}>{t('Close')}</a> */}
                <div className='select-category-top-content'>
                    <a href='' onClick={(e) => {
                        e.preventDefault()
                        ShopSiteStore.changeView(0)
                        UserLayoutStore.onTitleChange(t('All Painting'))
                    }}>{ 
                        t('All Painting')
                    }</a>
                    <a href='' onClick={(e) => {
                        e.preventDefault()
                        ShopSiteStore.changeView(1)
                        UserLayoutStore.onTitleChange(t('Stocking'))
                    }}>{ 
                        t('Stocking')
                    }</a>
                    <a href=''>Danh mục gì đó</a>
                    <a href=''>Danh mục gì đó</a>
                    <a href=''>Danh mục gì đó</a>
                    <a href=''>Danh mục gì đó</a>
                    <a href='#'>{t('Artist')}</a>
                    <a href='/contact-us'>{t('Contact Us')}</a>
                </div>
                <div className='select-category-bottom-content'>
                    <p><a href='/terms-of-use'>{t('Terms of use')}</a> | <a href='/privacy-policy'>{t('Privacy policy')}</a></p>
                    <p><a href='https://qframe.vn'>- QFRAME -</a></p>
                    <p><a href='/packaging-and-delivery'>{t('Packaging & Delivery')}</a> | <a href='/instruction'>{t('Instruction')}</a></p>
                </div>
            </div>
            <ExtraMenu />
            <div className='credit'>
                &copy; QFRAME 2022 | CREDITS
            </div>
            {/* {
                this.props.active === 'shop' ? <></>
                : <>
                    <div id='extraMenuButton' className="navTrigger" onClick={() => {
                        if($('#extraMenuButton').attr('class')?.includes('active')) {
                            $('#extraMenuButton').removeClass('active')
                            $('.extra-menu-wrapper').removeClass('show')
                        }
                        else {
                            $('#extraMenuButton').addClass('active')
                            $('.extra-menu-wrapper').addClass('show')
                        }
                    }}>
                        <i></i><i></i><i></i>
                    </div>
                </>
            } */}

            <div className={`search-modal-wrapper ${UserLayoutStore.searchModalActive ? 'active' : ''}`}>
                <div className='search-modal'>
                    <h4 className='text-center mb-4'>{t('SEARCH')}</h4>
                    <label className='mb-3'>{t('Keyword')}</label>
                    <input placeholder={t('Keyword')} className='custom-input mb-4' />
                    <label className='mb-3'>{t('Price')}</label>
                    <div className='price-filter mb-4'>
                        <div className='price-input'>
                            {t('Price from')} <input type='number' className='custom-input' placeholder='0' value={UserLayoutStore.priceFrom} onChange={(event) => {
                                UserLayoutStore.onPriceFromChange(event.target.value)
                            }} />
                        </div>
                        <div className='price-input'>
                            {t('to')}<input type='number'  className='custom-input' placeholder='0' maxLength={8} value={UserLayoutStore.priceTo} onChange={(event) => {
                                UserLayoutStore.onPriceToChange(event.target.value)
                            }} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 text-end'>
                            <button className='custom-button'>{t('SEARCH')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default observer(UserLayout)